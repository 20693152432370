.vertical-timeline *{

    -webkit-box-sizing:border-box;

            box-sizing:border-box}


.vertical-timeline{
    width:95%;
    max-width:1170px;
    margin:0 auto;
    position:relative;
    padding:2em 0
}
.vertical-timeline::after{
    content:'';
    display:table;
    clear:both
}
.vertical-timeline::before{
    content:'';
    position:absolute;
    top:0;
    left:18px;
    height:100%;
    width:4px;
    background:var(--line-color)
}
.vertical-timeline.vertical-timeline--one-column-right::before{
    right:18px;left:unset
}
@media only screen and (min-width:1170px){
    .vertical-timeline.vertical-timeline--two-columns{
        width:90%
    }
    .vertical-timeline.vertical-timeline--two-columns:before{
        left:50%;
        margin-left:-2px
    }
}
.vertical-timeline-element{
    position:relative;
    margin:2em 0
}
.vertical-timeline-element>div{
    min-height:1px
}
.vertical-timeline-element:after{
    content:"";
    display:table;
    clear:both
}
.vertical-timeline-element:first-child{
    margin-top:0
}
.vertical-timeline-element:last-child{
    margin-bottom:0
}

@media only screen and (min-width:1170px){
    .vertical-timeline-element{
            margin:4em 0
    }

    .vertical-timeline-element:first-child{
        margin-top:0
    }
    .vertical-timeline-element:last-child{
        margin-bottom:0
    }

}
.vertical-timeline-element-icon{
    position:absolute;
    top:0;
    left:0;
    width:50px;
    height:50px;
    border-radius:50%;
        -webkit-box-shadow: 0 0 0 4px #32c6e7, inset 0 2px 0 rgba(0, 0, 0, .08), 0 3px 0 4px rgba(0, 0, 0, .05) !important;
    box-shadow:
        0 0 0 4px #32c6e7,inset 0 2px 0 rgba(0,0,0,.08),0 3px 0 4px rgba(0,0,0,.05) !important
}
.vertical-timeline--one-column-right .vertical-timeline-element-icon{
    right:0;
    left:unset
}
.vertical-timeline-element-icon svg{
    display:block;
    width:24px;
    height:24px;
    position:relative;
    left:50%;
    top:50%;
    margin-left:-12px;
    margin-top:-12px
}
@media only screen and (min-width:1170px){
    .vertical-timeline--two-columns .vertical-timeline-element-icon{
        width:60px;
        height:60px;
        left:50%;
        margin-left:-30px
    }
}
.vertical-timeline-element-icon{
    -webkit-transform:translateZ(0);
    -webkit-backface-visibility:hidden
}
.vertical-timeline--animate 
.vertical-timeline-element-icon.is-hidden{
    visibility:hidden
}
.vertical-timeline--animate
 .vertical-timeline-element-icon.bounce-in{
    visibility:visible;
    -webkit-animation:cd-bounce-1 .6s;
    animation:cd-bounce-1 .6s
}
@-webkit-keyframes cd-bounce-1{
    0%{
        opacity:0;
        -webkit-transform:scale(.5)
    }
    60%{
        opacity:1;
        -webkit-transform:scale(1.2)
    }
    100%{
        -webkit-transform:scale(1)
    }
}
@keyframes cd-bounce-1{
    0%{
        opacity:0;
        -webkit-transform:scale(.5);
        transform:scale(.5)
    }
    60%{
        opacity:1;
        -webkit-transform:scale(1.2);
        transform:scale(1.2)
    }
    100%{-webkit-transform:scale(1);
        transform:scale(1)
    }

}
.vertical-timeline-element-content{
    position:relative;
    margin-left:60px;
    background:#c2d3db;
    border-radius:.25em;
    padding:1em;
    -webkit-box-shadow:0 3px 0 #0d6efd;
            box-shadow:0 3px 0 #0d6efd
}
.vertical-timeline--one-column-right .vertical-timeline-element-content{
    margin-right:60px;
    margin-left:unset
}
.vertical-timeline-element--no-children .vertical-timeline-element-content{
    background:0 0;
    -webkit-box-shadow:none;
            box-shadow:none
}
.vertical-timeline-element-content:after{
    content:"";
    display:table;
    clear:both
}
.vertical-timeline-element-content h2{
    color:#303e49
}
.vertical-timeline-element-content .vertical-timeline-element-date,.vertical-timeline-element-content p{
    font-size:13px;
    font-size:.8125rem;
    font-weight:500
}
.vertical-timeline-element-content .vertical-timeline-element-date{
    display:inline-block
}
.vertical-timeline-element-content p{
    margin:1em 0 0;
    line-height:1.6
}
.vertical-timeline-element-title{
    margin:0
}
.vertical-timeline-element-subtitle{
    margin:0
}
.vertical-timeline-element-content .vertical-timeline-element-date{
    float:left;
    padding:.8em 0;
    opacity:.7
}
.vertical-timeline-element-content-arrow{
    content:'';
    position:absolute;
    top:16px;
    right:100%;
    height:0;
    width:0;
    border:7px solid transparent;
    border-right:7px solid #fff
}
.vertical-timeline--one-column-right .vertical-timeline-element-content-arrow{
    content:'';
    position:absolute;
    top:16px;
    right:unset;
    left:100%;
    height:0;
    width:0;
    border:7px solid transparent;
    border-left:7px solid #fff
}
.vertical-timeline--one-column-right .vertical-timeline-element-content::before{
    left:100%;
    border-left:7px solid #fff;
    border-right:unset
}
.vertical-timeline-element--no-children .vertical-timeline-element-content::before{
    display:none
}
.vertical-timeline-element--no-children .vertical-timeline-element-content-arrow{
    display:none
}
@media only screen and (min-width:768px){
    .vertical-timeline-element-content h2{
    font-size:20px;
    font-size:1.25rem
}
.vertical-timeline-element-content p{
    font-size:16px;
    font-size:1rem
}
.vertical-timeline-element-content .vertical-timeline-element-date{
    font-size:14px;
    font-size:.875rem
}

}
@media only screen and (min-width:1170px){
    .vertical-timeline--two-columns .vertical-timeline-element-content{
    margin-left:0;
    padding:1.5em;
    width:44%
}
.vertical-timeline--two-columns .vertical-timeline-element-content-arrow{
    top:24px;
    left:100%;
    -webkit-transform:rotate(180deg);
            transform:rotate(180deg)
}
.vertical-timeline--two-columns
 .vertical-timeline-element-content 
 .vertical-timeline-element-date{
    position:absolute;
    width:100%;
    left:124%;
    top:6px;
    font-size:16px;
    font-size:1rem
}
.vertical-timeline--two-columns 
.vertical-timeline-element
.vertical-timeline-element--right 
.vertical-timeline-element-content,
.vertical-timeline--two-columns 
.vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left)
 .vertical-timeline-element-content{
    float:right
}
.vertical-timeline--two-columns 
.vertical-timeline-element.vertical-timeline-element--right
 .vertical-timeline-element-content-arrow,
 .vertical-timeline--two-columns 
 .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) 
 .vertical-timeline-element-content-arrow{
    top:24px;
    left:auto;
    right:100%;
    -webkit-transform:rotate(0);
            transform:rotate(0)
}
.vertical-timeline--one-column-right
 .vertical-timeline-element
.vertical-timeline-element--right 
.vertical-timeline-element-content-arrow,
.vertical-timeline--one-column-right 
.vertical-timeline-element:nth-child(even):not(
    .vertical-timeline-element--left
) 
.vertical-timeline-element-content-arrow{
    top:24px;
    left:100%;
    right:auto;
    -webkit-transform:rotate(0);
            transform:rotate(0)
}

.vertical-timeline--two-columns .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content .vertical-timeline-element-date,.vertical-timeline--two-columns .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content .vertical-timeline-element-date{
    left:auto;
    right:124%;
    text-align:right
}

}
.vertical-timeline--animate .vertical-timeline-element-content.is-hidden{
    visibility:hidden}
.vertical-timeline--animate .vertical-timeline-element-content.bounce-in{
    visibility:visible;
    -webkit-animation:cd-bounce-2 .6s;
    animation:cd-bounce-2 .6s}
@media only screen and (min-width:1170px){
    .vertical-timeline--two-columns.vertical-timeline--animate .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content.bounce-in,.vertical-timeline--two-columns.vertical-timeline--animate .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content.bounce-in{
    -webkit-animation:cd-bounce-2-inverse .6s;
    animation:cd-bounce-2-inverse .6s}
}
@media only screen and (max-width:1169px){
    .vertical-timeline--animate .vertical-timeline-element-content.bounce-in{
    visibility:visible;
    -webkit-animation:cd-bounce-2-inverse .6s;
    animation:cd-bounce-2-inverse .6s}
}
@-webkit-keyframes cd-bounce-2{
    0%{
    opacity:0;
    -webkit-transform:translateX(-100px)}
60%{
    opacity:1;
    -webkit-transform:translateX(20px)}
100%{
    -webkit-transform:translateX(0)}
}
@keyframes cd-bounce-2{
    0%{
    opacity:0;
    -webkit-transform:translateX(-100px);
    transform:translateX(-100px)}
60%{
    opacity:1;
    -webkit-transform:translateX(20px);
    transform:translateX(20px)}
100%{
    -webkit-transform:translateX(0);
    transform:translateX(0)}
}
@-webkit-keyframes cd-bounce-2-inverse{
    0%{
    opacity:0;
    -webkit-transform:translateX(100px)}
60%{
    opacity:1;
    -webkit-transform:translateX(-20px)}
100%{
    -webkit-transform:translateX(0)}
}
@keyframes cd-bounce-2-inverse{
    0%{
    opacity:0;
    -webkit-transform:translateX(100px);
    transform:translateX(100px)}
60%{
    opacity:1;
    -webkit-transform:translateX(-20px);
    transform:translateX(-20px)}
100%{
    -webkit-transform:translateX(0);
    transform:translateX(0)}
}

